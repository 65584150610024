import React from "react";
import Panel from "@vkontakte/vkui/dist/components/Panel/Panel";
import PanelHeader from "@vkontakte/vkui/dist/components/PanelHeader/PanelHeader";
import Button from "@vkontakte/vkui/dist/components/Button/Button";
//import Group from '@vkontakte/vkui/dist/components/Group/Group';
//import Div from '@vkontakte/vkui/dist/components/Div/Div';
import FormLayout from "@vkontakte/vkui/dist/components/FormLayout/FormLayout";
import PanelHeaderBack from "@vkontakte/vkui/dist/components/PanelHeaderBack/PanelHeaderBack";
//import FormLayoutGroup from '@vkontakte/vkui/dist/components/FormLayoutGroup/FormLayoutGroup';
import Input from "@vkontakte/vkui/dist/components/Input/Input";
import Textarea from "@vkontakte/vkui/dist/components/Textarea/Textarea";
import Select from "@vkontakte/vkui/dist/components/Select/Select";

const RegularFundry = ({ id, go, formData, setFormData }) => {
  const changeData = (key, value) => {
    formData[key] = value;
    setFormData(formData);
  };
  const getVal = (key) => {
    return formData[key];
  };
  return (
    <Panel id={id}>
      <PanelHeader
        left={<PanelHeaderBack onClick={go} data-to="createFoundy" />}
      >
        Регулярный сбор
      </PanelHeader>

      <FormLayout>
        <Input
          top="Название сбора"
          type="text"
          placeholder="Название сбора"
          defaultValue={getVal("name")}
          onChange={(e) => {
            changeData("name", e.target.value);
          }}
        />

        <Input
          top="Сумма в месяц, ₽"
          type="text"
          placeholder="Сколько нужно в месяц?"
          defaultValue={getVal("volume")}
          onChange={(e) => {
            changeData("volume", e.target.value);
          }}
        />

        <Input
          top="Цель"
          type="text"
          placeholder="Например, поддержка приюта"
          defaultValue={getVal("target")}
          onChange={(e) => {
            changeData("target", e.target.value);
          }}
        />

        <Textarea
          top="Описание"
          type="text"
          placeholder="На что пойдут деньги и как они комуто помогут"
          defaultValue={getVal("description")}
          onChange={(e) => {
            changeData("description", e.target.value);
          }}
        />
        <Select top="Куда получать деньги">
          <option>Счет VK Pay • 1234</option>
          <option>Счет VK Pay • 2351</option>
        </Select>
        <Select top="Автор">
          <option>Матвей</option>
          <option>Правосудов</option>
        </Select>

        <Button size="xl" onClick={go} data-to="newsFeed">
          Создать
        </Button>
      </FormLayout>
    </Panel>
  );
};

export default RegularFundry;
