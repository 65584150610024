import React, { useState } from "react";
// import bridge from "@vkontakte/vk-bridge";
import View from "@vkontakte/vkui/dist/components/View/View";
// import ScreenSpinner from "@vkontakte/vkui/dist/components/ScreenSpinner/ScreenSpinner";
import "@vkontakte/vkui/dist/vkui.css";

import Home from "./panels/Home";
// import Persik from "./panels/Persik";
import CreateFoundry from "./panels/CreateFoundry";
import RegularFundry from "./panels/RegularFundry";
import TargetFundry from "./panels/TargetFundry";
import NewsFeed from "./panels/NewsFeed";
import FoundryPage from "./panels/FoundryPage";
import TargetFundryDop from "./panels/TargetFundryDop";

const App = () => {
  const [activePanel, setActivePanel] = useState(
    "home"
  );
  const [formData, setFormData] = useState({
    name: "",
    volume: "",
    description: "",
    target: "",
  })
  //const [fetchedUser, setUser] = useState(null);
  //const [popout, setPopout] = useState(<ScreenSpinner size="large" />);

  /*
	  useEffect(() => {
		  bridge.subscribe(({ detail: { type, data }}) => {
			  if (type === 'VKWebAppUpdateConfig') {
				  const schemeAttribute = document.createAttribute('scheme');
				  schemeAttribute.value = data.scheme ? data.scheme : 'client_light';
				  document.body.attributes.setNamedItem(schemeAttribute);
			  }
		  });
		  async function fetchData() {
			  const user = await bridge.send('VKWebAppGetUserInfo');
			  setUser(user);
			  setPopout(null);
		  }
		  fetchData();
	  }, []);
	  */

  const go = (e) => {
    setActivePanel(e.currentTarget.dataset.to);
  };

  return (
    <View activePanel={activePanel}>
      <Home id="home" go={go}
      />
      <CreateFoundry id="createFoundy" go={go} />
      <RegularFundry id="regularFundry" go={go} formData={formData} setFormData={setFormData} />
      <NewsFeed id="newsFeed" go={go} formData={formData} setFormData={setFormData} />
      <TargetFundry id="targetFundry" go={go} formData={formData} setFormData={setFormData} />
      <FoundryPage id="foundryPage" go={go} formData={formData} setFormData={setFormData} />
      <TargetFundryDop id="targetFundryDop" go={go} formData={formData} setFormData={setFormData} />
    </View>
  );
};

/*
			<Home id='home' fetchedUser={fetchedUser} go={go} />
			<Persik id='persik' go={go} />
			*/

export default App;
