import React from "react";
import Panel from "@vkontakte/vkui/dist/components/Panel/Panel";
import PanelHeader from "@vkontakte/vkui/dist/components/PanelHeader/PanelHeader";
import Button from "@vkontakte/vkui/dist/components/Button/Button";
import PanelHeaderBack from "@vkontakte/vkui/dist/components/PanelHeaderBack/PanelHeaderBack";
//import Group from "@vkontakte/vkui/dist/components/Group/Group";
//import Div from "@vkontakte/vkui/dist/components/Div/Div";
import FormLayout from "@vkontakte/vkui/dist/components/FormLayout/FormLayout";
import FormLayoutGroup from "@vkontakte/vkui/dist/components/FormLayoutGroup/FormLayoutGroup";
import Input from "@vkontakte/vkui/dist/components/Input/Input";
import Select from "@vkontakte/vkui/dist/components/Select/Select";
import Radio from "@vkontakte/vkui/dist/components/Radio/Radio";

const TargetFundryDop = ({ id, go }) => (
  <Panel id={id}>
    <PanelHeader left={<PanelHeaderBack onClick={go} data-to="targetFundry" />}>
      Целевой сбор
    </PanelHeader>

    <FormLayout>
      <Select top="Автор">
        <option>Матвей</option>
        <option>Правосудов</option>
      </Select>

      <FormLayoutGroup top="Сбор завершится">
        <Radio name="radio" value="1" defaultChecked>
          Когда соберем сумму
        </Radio>
        <Radio name="radio" value="2">
          В определенную дату
        </Radio>
      </FormLayoutGroup>

      <Input top="Дата окончания" type="date" placeholder="Выберите дату" />

      <Button size="xl" onClick={go} data-to="newsFeed">
        Создать
      </Button>
    </FormLayout>
  </Panel>
);

export default TargetFundryDop;
