import React from "react";

import Icon24LikeOutline from "@vkontakte/icons/dist/24/like_outline";
import Icon24CommentOutline from "@vkontakte/icons/dist/24/comment_outline";
import Icon24ShareOutline from "@vkontakte/icons/dist/24/share_outline";
import Icon24View from "@vkontakte/icons/dist/24/view";

import SimpleCell from "@vkontakte/vkui/dist/components/SimpleCell/SimpleCell";
import Text from "@vkontakte/vkui/dist/components/Typography/Text/Text";

const NewsFeedIcons = () => (
  <SimpleCell
    after={
      <div className="news-feed-card-icons">
        <Icon24View fill="var(--icon_tertiary)" />
        <Text weight="medium" style={{ color: "var(--header_text_secondary)" }}>
          60
        </Text>
      </div>
    }
  >
    <div className="news-feed-card-icons-wraper">
      <div className="news-feed-card-icons">
        <Icon24LikeOutline fill="var(--icon_tertiary)" />
        <Text weight="medium" style={{ color: "var(--header_text_secondary)" }}>
          60
        </Text>
      </div>
      <div className="news-feed-card-icons">
        <Icon24CommentOutline fill="var(--icon_tertiary)" />
        <Text weight="medium" style={{ color: "var(--header_text_secondary)" }}>
          60
        </Text>
      </div>
      <div className="news-feed-card-icons">
        <Icon24ShareOutline fill="var(--icon_tertiary)" />
        <Text weight="medium" style={{ color: "var(--header_text_secondary)" }}>
          60
        </Text>
      </div>
    </div>
  </SimpleCell>
);
export default NewsFeedIcons;
