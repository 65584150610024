import React from "react";
import Panel from "@vkontakte/vkui/dist/components/Panel/Panel";
import Div from "@vkontakte/vkui/dist/components/Div/Div";
import PanelHeader from "@vkontakte/vkui/dist/components/PanelHeader/PanelHeader";
import Tabs from "@vkontakte/vkui/dist/components/Tabs/Tabs";
import Tabbar from "@vkontakte/vkui/dist/components/Tabbar/Tabbar";
import TabbarItem from "@vkontakte/vkui/dist/components/TabbarItem/TabbarItem";
import TabsItem from "@vkontakte/vkui/dist/components/TabsItem/TabsItem";
import Epic from "@vkontakte/vkui/dist/components/Epic/Epic";
import Button from "@vkontakte/vkui/dist/components/Button/Button";

import SimpleCell from "@vkontakte/vkui/dist/components/SimpleCell/SimpleCell";
import Cell from "@vkontakte/vkui/dist/components/Cell/Cell";
import Avatar from "@vkontakte/vkui/dist/components/Avatar/Avatar";
import CardGrid from "@vkontakte/vkui/dist/components/CardGrid/CardGrid";
import Card from "@vkontakte/vkui/dist/components/Card/Card";
import Separator from "@vkontakte/vkui/dist/components/Separator/Separator";
import InfoRow from "@vkontakte/vkui/dist/components/InfoRow/InfoRow";
import Progress from "@vkontakte/vkui/dist/components/Progress/Progress";

import Text from "@vkontakte/vkui/dist/components/Typography/Text/Text";

import Icon28NewsfeedOutline from "@vkontakte/icons/dist/28/newsfeed_outline";
import Icon28ServicesOutline from "@vkontakte/icons/dist/28/services_outline";
import Icon28MessageOutline from "@vkontakte/icons/dist/28/messages_outline";
import Icon28ClipOutline from "@vkontakte/icons/dist/28/clip_outline";
import Icon28UserCircleOutline from "@vkontakte/icons/dist/28/user_circle_outline";

import Icon28CameraOutline from "@vkontakte/icons/dist/28/camera_outline";
import Icon28Notifications from "@vkontakte/icons/dist/28/notifications";
import Icon16Dropdown from "@vkontakte/icons/dist/16/dropdown";
import Icon24MoreHorizontal from "@vkontakte/icons/dist/24/more_horizontal";

import "./NewsFeed.css";

import NewsFeedIcons from "./NewsFeedIcons";

const NewsFeed = ({ id, go, formData, setFormData }) => {
  return (
    <Panel id={id}>
      <Epic
        activeStory="feed"
        tabbar={
          <Tabbar>
            <TabbarItem data-story="feed" text="Новости">
              <Icon28NewsfeedOutline />
            </TabbarItem>
            <TabbarItem data-story="services" text="Сервисы">
              <Icon28ServicesOutline />
            </TabbarItem>
            <TabbarItem data-story="messages" label="12" text="Сообщения">
              <Icon28MessageOutline />
            </TabbarItem>
            <TabbarItem data-story="clips" text="Клипы">
              <Icon28ClipOutline />
            </TabbarItem>
            <TabbarItem data-story="profile" text="Профиль">
              <Icon28UserCircleOutline />
            </TabbarItem>
          </Tabbar>
        }
      ></Epic>

      <PanelHeader
        left={
          <Div>
            <Icon28CameraOutline />
          </Div>
        }
        right={
          <Div>
            <Icon28Notifications />
          </Div>
        }
        separator={false}
      >
        <Tabs>
          <TabsItem selected after={<Icon16Dropdown fill="var(--accent)" />}>
            Новости
          </TabsItem>
          <TabsItem>Интересное</TabsItem>
        </Tabs>
      </PanelHeader>
      <SimpleCell
        description="Час назад"
        before={
          <Avatar src="https://sun9-61.userapi.com/O-2f7t0yecmx38WXoF37RkhkJTG2rcjL4Yq88w/J39s0u1f90c.jpg?ava=1" />
        }
        after={<Icon24MoreHorizontal fill="var(--icon_tertiary)" />}
      >
        Артур Стамбульцян
      </SimpleCell>
      <Div>
        <Text>
          Сейчас самое время помочь тем, кто не может попросить о помощи сам
        </Text>
      </Div>
      <CardGrid>
        <Card size="l" mode="shadow" className="custom-card">
          <div
            className="custom-card-backgroud"
            style={{
              backgroundImage:
                "url(https://images.theconversation.com/files/350865/original/file-20200803-24-50u91u.jpg?ixlib=rb-1.1.0&rect=37%2C29%2C4955%2C3293&q=45&auto=format&w=926&fit=clip)",
            }}
          ></div>
          <Div>
            <Text weight="medium">{formData["name"]}</Text>
          </Div>
          <Separator />

          <Cell
            asideContent={
              <Button mode="outline" onClick={go} data-to="foundryPage">
                Помочь
              </Button>
            }
          >
            <InfoRow header={`Собрано в сентябре ${formData["volume"]}  ₽`}>
              <Progress value={40} />
            </InfoRow>
          </Cell>
        </Card>
      </CardGrid>
      <NewsFeedIcons />
      <div className="new-feed-separator"></div>
      <SimpleCell
        description="Час назад"
        before={
          <Avatar src="https://sun9-19.userapi.com/c846020/v846020298/1d0e79/R41LGoPtIK0.jpg?ava=1" />
        }
        after={<Icon24MoreHorizontal fill="var(--icon_tertiary)" />}
      >
        Евгений Авсиевич
      </SimpleCell>
      <Div>
        <Text>
          Большая часть текста сделана из разделов 1.10.32–3 книги Цицерона «На
          грани Добра и Зла» («De finibus bonorum et malorum»). Neque porro
          quisquam est qui dolorem ipsum quia dolor sit amet, consectetur,
          adipisci velit является первой известной человечеству версией («Больше
          нет никого из тех, кто любит скорбь, поскольку это горе и поэтому
          хочет заполучить это»).
        </Text>
      </Div>
      <NewsFeedIcons />
      <div className="new-feed-separator"></div>
      <div className="new-feed-separator"></div>
      <div className="new-feed-separator"></div>
      <div className="new-feed-separator"></div>
    </Panel>
  );
};

export default NewsFeed;
