import React from "react";
import Panel from "@vkontakte/vkui/dist/components/Panel/Panel";
import PanelHeader from "@vkontakte/vkui/dist/components/PanelHeader/PanelHeader";
import Button from "@vkontakte/vkui/dist/components/Button/Button";
import Group from "@vkontakte/vkui/dist/components/Group/Group";
import Div from "@vkontakte/vkui/dist/components/Div/Div";
import PanelHeaderBack from "@vkontakte/vkui/dist/components/PanelHeaderBack/PanelHeaderBack";

import Text from "@vkontakte/vkui/dist/components/Typography/Text/Text";
import Headline from "@vkontakte/vkui/dist/components/Typography/Headline/Headline";

import PropTypes from "prop-types";
import Icon28TargetOutline from "@vkontakte/icons/dist/28/target_outline";
import Icon24BrowserForward from "@vkontakte/icons/dist/24/browser_forward";
import Icon28CalendarOutline from "@vkontakte/icons/dist/28/calendar_outline";

const CreateFoundry = ({ id, go }) => (
  <Panel id={id}>
    <PanelHeader left={<PanelHeaderBack onClick={go} data-to="home" />}>Тип сбора</PanelHeader>

    <div className="centered-container">
      <Group>
        <Div>
          <Button
            onClick={go}
            data-to="targetFundry"
            before={<Icon28TargetOutline />}
            after={<Icon24BrowserForward fill="var(--icon_tertiary)" />}
            size="xl"
            mode="secondary"

          >
            <Headline style={{
              color: "var(--text_primary)"

            }}>Целевой сбор</Headline>
            <Text style={{
              color: "var(--header_text_secondary)"
            }}>
              Когда есть определённая цель
            </Text>
          </Button>
          <Button
            onClick={go}
            data-to="regularFundry"
            before={<Icon28CalendarOutline />}
            after={<Icon24BrowserForward fill="var(--icon_tertiary)" />}
            size="xl"
            mode="secondary"
          >
            <Headline style={{
              color: "var(--text_primary)"

            }}>Регулярный сбор</Headline>
            <Text style={{
              color: "var(--header_text_secondary)"
            }}>
              Если помощь нужна ежемесячно
            </Text>

          </Button>
        </Div>
      </Group>
    </div>
  </Panel >
);

CreateFoundry.propTypes = {
  id: PropTypes.string.isRequired,
  go: PropTypes.func.isRequired,
};
export default CreateFoundry;
