import React, { useState, useEffect } from "react";
import Panel from "@vkontakte/vkui/dist/components/Panel/Panel";
import Separator from "@vkontakte/vkui/dist/components/Separator/Separator";
import Div from "@vkontakte/vkui/dist/components/Div/Div";
import InfoRow from "@vkontakte/vkui/dist/components/InfoRow/InfoRow";
import FixedLayout from "@vkontakte/vkui/dist/components/FixedLayout/FixedLayout";
import Cell from "@vkontakte/vkui/dist/components/Cell/Cell";
import Avatar from "@vkontakte/vkui/dist/components/Avatar/Avatar";
import SimpleCell from "@vkontakte/vkui/dist/components/SimpleCell/SimpleCell";
import Button from "@vkontakte/vkui/dist/components/Button/Button";
import Progress from "@vkontakte/vkui/dist/components/Progress/Progress";

import Title from "@vkontakte/vkui/dist/components/Typography/Title/Title";
import Text from "@vkontakte/vkui/dist/components/Typography/Text/Text";
import Caption from "@vkontakte/vkui/dist/components/Typography/Caption/Caption";
import Input from "@vkontakte/vkui/dist/components/Input/Input";

import Icon16LikeOutline from "@vkontakte/icons/dist/16/like_outline";

import "./FoundryPage.css";

import NewsFeedIcons from "./NewsFeedIcons";

const FoundryPage = ({ id, go, formData, setFormData }) => {
  const totalNeed = formData['volume'];

  const [current, setCurrent] = useState(0);

  const [totalProc, setTotalProc] = useState(0);

  useEffect(() => {
    console.log(current);
    setTotalProc(current / (totalNeed / 100));
  }, [current, totalNeed]);

  const addCurretn = () => {
    if (totalNeed > current) {
      if (current + 1000 > totalNeed) {
        setCurrent(totalNeed);
      } else {
        setCurrent(current + 1000);
      }

    }
  };
  return (
    <Panel id={id}>
      <div
        className="foundry-page-top-image"
        style={{
          backgroundImage:
            "url(https://images.theconversation.com/files/350865/original/file-20200803-24-50u91u.jpg?ixlib=rb-1.1.0&rect=37%2C29%2C4955%2C3293&q=45&auto=format&w=926&fit=clip)",
        }}
      ></div>
      <Div>
        <Title level="1" weight="bold">
          Добряши помогают котикам
          <InfoRow header={`Собрано в сентябре ${current}  ₽`}>
            <Caption
              level="1"
              weight="regular"
              style={{
                color: "var(--content_placeholder_text)",
              }}
            >
              Автор Матвей Правосудов
            </Caption>
          </InfoRow>
        </Title>
      </Div>
      <Separator />
      <Div className="progress-container ">
        <Text>Нужно собрать до 10 сентября</Text>
        <div className="progress-wraper">
          <div
            className="progress-fill"
            style={{
              width: `${totalProc}%`,
              color: totalProc < 30 ? `var(--button_commerce_background)` : "",
              textIndent: totalProc < 30 ? "" : "0%",
            }}
          >
            <Text weight="semibold">{current} ₽</Text>
          </div>
          <div
            className="progress-total-text"
            style={{
              top: totalProc > 75 ? "12px" : "35px",
            }}
          >
            <Text weight="semibold">{totalNeed} ₽</Text>
          </div>
        </div>
      </Div>
      <Separator />
      <Div>
        <Text>
          {formData['description']}
        </Text>
      </Div>
      <Separator />
      <NewsFeedIcons />
      <Separator wide />

      <SimpleCell
        before={
          <Avatar src="https://sun9-61.userapi.com/O-2f7t0yecmx38WXoF37RkhkJTG2rcjL4Yq88w/J39s0u1f90c.jpg?ava=1" />
        }
        after={<Icon16LikeOutline fill="var(--icon_tertiary)" />}
      >
        Артур Стамбульцян
        <Text>Отправил</Text>
      </SimpleCell>

      <SimpleCell
        before={
          <Avatar src="https://sun9-61.userapi.com/O-2f7t0yecmx38WXoF37RkhkJTG2rcjL4Yq88w/J39s0u1f90c.jpg?ava=1" />
        }
      >
        <Input placeholder="Комментарий" />
      </SimpleCell>

      <FixedLayout vertical="bottom" className="whiteback">
        <Separator wide />
        <Cell
          asideContent={
            <Button mode="commerce" onClick={addCurretn}>
              Помочь
            </Button>
          }
        >
          <InfoRow header={`Собрано в сентябре ${current} из ${totalNeed} ₽`}>
            <Progress className="custom-progress" value={totalProc} />
          </InfoRow>
        </Cell>
      </FixedLayout>
    </Panel>
  );
};
export default FoundryPage;
